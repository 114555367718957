import { useEffect, useState } from 'react';
import { useQuery } from 'src/react-query';
import { useIsClientLimitModel, useClientLimitModelTier, useHasAccess } from 'cp-client-auth!sofe';
import { canopyPaymentsQueries } from '../canopyPaymentsQueries';
import { OnboardingStatusData } from 'src/types/common';

export const usePaymentsOnboardingQuery = () => {
  const hasBillingSettings = useHasAccess('company_settings_billing');
  const [onboardingStatus, setOnboardingStatus] = useState<OnboardingStatusData | null>(null);

  const { data } = useQuery({ ...canopyPaymentsQueries.paymentsOnboardingStatus({ hasBillingSettings }) });

  useEffect(() => {
    setOnboardingStatus(data);
  }, [data]);

  const isClientLimitModel = useIsClientLimitModel();
  const clientLimitModelTier = useClientLimitModelTier();
  const canOnboard =
    (hasBillingSettings &&
      (isClientLimitModel ? clientLimitModelTier === 'PAID' : onboardingStatus?.payments_allowed)) ||
    false;

  return { canOnboard, onboardingStatus, setOnboardingStatus };
};
