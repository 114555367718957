import { genQueries, queryOptions } from 'src/react-query';
import { getPaymentsOnboardingStatus } from 'src/resources/on-boarding.resources';
import { getCanopyPaymentsStatus } from 'src/resources/payments.resources';

export const canopyPaymentsQueries = genQueries(
  'canopyPaymentsQueries',
  ({ genKey }: { genKey: (key: string, params: object) => string }) => ({
    paymentsStatus: ({ override }: { override: boolean }) =>
      queryOptions({
        queryKey: genKey('paymentsStatus', {}),
        queryFn: () => getCanopyPaymentsStatus(),
        staleTime: 1000 * 60 * 60,
        enabled: override === undefined,
      }),
    paymentsOnboardingStatus: ({ hasBillingSettings }: { hasBillingSettings: boolean }) =>
      queryOptions({
        queryKey: genKey('paymentsOnboardingStatus', {}),
        queryFn: () => getPaymentsOnboardingStatus(),
        staleTime: 1000 * 60 * 60,
        enabled: hasBillingSettings,
      }),
  })
);
